import Dashboard from './Dashboard/containers/Dashboard';
import DashboardNotFound from './Dashboard/containers/DashboardNotFound';
import CspDetail from './Csp/containers/CspDetail';
import CspListing from './Csp/containers/CspListing';
import BrandDetail from './Brands/containers/BrandDetail';
import BrandsListing from './Brands/containers/BrandsListing';
import BrandSuspension from './Brands/containers/BrandSuspension';
import CampaignsListing from './Campaigns/containers/CampaignsListing';
import CampaignDetail from './Campaigns/containers/CampaignDetail';
import UniversalEinListing from './UniversalEin/containers/UniversalEinListing';
import Events from './Events/containers/Events';
import Integrations from './Integrations/containers/Integrations';
import { ProfilePage } from './Profile';
import { HeaderHoc, ErrorBoundaryHoc } from '../../hocs';
import PhoneHistory from './Phones/containers/PhoneHistory';
import SuspendedCampaigns from './Campaigns/containers/SuspendedCampaigns';

export const mnoRoutes = [
  {
    path: '/dashboard',
    component: HeaderHoc(ErrorBoundaryHoc(Dashboard), {
      title: 'DASHBOARD',
    }),
    key: 'dashboard',
  },
  {
    path: '/dashboard/not-found',
    component: HeaderHoc(ErrorBoundaryHoc(DashboardNotFound), {
      title: '',
    }),
    key: 'dashboard-not-found',
  },
  {
    path: '/csp/:id',
    component: HeaderHoc(ErrorBoundaryHoc(CspDetail), { title: 'CSP PROFILE' }),
    key: 'csp-detail',
  },
  {
    path: '/csps',
    component: HeaderHoc(ErrorBoundaryHoc(CspListing), { title: 'CSPs' }),
    key: 'csp-listing',
  },
  {
    path: '/brands/:id',
    component: HeaderHoc(ErrorBoundaryHoc(BrandDetail), {
      title: 'BRAND DETAILS',
    }),
    key: 'brand-detail',
  },
  {
    path: '/brands/',
    component: HeaderHoc(ErrorBoundaryHoc(BrandsListing), { title: 'BRANDS' }),
    key: 'brand-listing',
  },
  {
    path: '/brand-suspension/:id',
    component: HeaderHoc(ErrorBoundaryHoc(BrandSuspension), {
      title: 'BRAND SUSPENSION',
    }),
    key: 'brand-suspension',
  },
  {
    path: '/universal-ein/:id',
    component: HeaderHoc(ErrorBoundaryHoc(UniversalEinListing), {
      title: 'Universal EIN',
    }),
    key: 'universal-listing',
  },
  {
    path: '/universal-ein/:id/campaigns',
    component: HeaderHoc(ErrorBoundaryHoc(UniversalEinListing), {
      title: 'Universal EIN',
    }),
    key: 'universal-listing',
  },
  {
    path: '/universal-ein/:id/brands',
    component: HeaderHoc(ErrorBoundaryHoc(UniversalEinListing), {
      title: 'Universal EIN',
    }),
    key: 'universal-listing',
  },
  {
    path: '/phones/:phone',
    component: HeaderHoc(PhoneHistory, {
      title: 'Phone Number History',
    }),
    key: 'phone-history',
  },
  {
    path: '/campaigns',
    component: HeaderHoc(CampaignsListing, { title: 'CAMPAIGNS' }),
    key: 'campaigns-listing',
  },
  {
    path: '/review-campaigns',
    component: HeaderHoc(ErrorBoundaryHoc(CampaignsListing), {
      title: 'CAMPAIGNS',
    }),
    key: 'review-campaigns-listing',
  },
  {
    path: '/suspended-campaigns',
    component: HeaderHoc(ErrorBoundaryHoc(SuspendedCampaigns), {
      title: 'Suspended Campaigns',
    }),
    key: 'suspended-campaigns-listing',
  },
  {
    path: '/campaigns/:id',
    component: HeaderHoc(ErrorBoundaryHoc(CampaignDetail), {
      title: 'CAMPAIGN DETAILS',
    }),
    key: 'campaign-detail',
  },
  {
    path: '/events',
    component: HeaderHoc(ErrorBoundaryHoc(Events), { title: 'EVENTS' }),
    key: 'events-listing',
  },
  {
    path: '/integrations',
    component: HeaderHoc(ErrorBoundaryHoc(Integrations), {
      title: 'INTEGRATIONS',
    }),
    key: 'integrations',
  },
  {
    path: '/profile',
    component: HeaderHoc(ErrorBoundaryHoc(ProfilePage), {
      title: 'MNO Profile',
    }),
    key: 'profile',
  },
];

import { BoxV2 as Box, Flex } from 'portal-commons';
import { FunctionComponent } from 'react';
import { Tooltip, TooltipProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  tooltip: {
    padding: 0,
    color: '#FFFFFF',
    background: '#0091B3',
  },
  arrow: {
    width: '26px',
    height: '18px',
    color: '#0091B3',
    transform: 'scaleX(0.5)',
  },
  placementTop: {
    '& > .MuiTooltip-arrow': {
      marginBottom: '-18px !important',
    },
  },
  placementBottom: {
    '& > .MuiTooltip-arrow': {
      marginTop: '-18px !important',
    },
  },
});

const ExplanationTooltip: FunctionComponent<TooltipProps> = ({
  title,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
        tooltipPlacementTop: classes.placementTop,
        tooltipPlacementBottom: classes.placementBottom,
      }}
      arrow
      interactive
      title={
        <Flex
          sx={{
            maxHeight: '40vh',
            padding: '8px 16px',
            flexDirection: 'column',
            rowGap: '4px',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            overflowY: 'auto',
          }}
        >
          <Box sx={{ fontWeight: 600 }}>EXPLANATION</Box>
          <Box sx={{ whiteSpace: 'pre-wrap' }}>{title}</Box>
        </Flex>
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default ExplanationTooltip;

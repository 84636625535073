import React from 'react';
import { useHistory } from 'react-router-dom';
import { BoxV2 as Box, Flex, Button } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faHand } from '@fortawesome/pro-regular-svg-icons';

const CampaignSuspendSection: React.FC<{ count: number }> = ({ count }) => {
  const history = useHistory();

  return (
    <Box
      sx={{
        p: 16,
        height: 142,
        minWidth: 280,
        border: '1px solid #B3B7B8',
        borderRadius: 16,
        flex: 1,
      }}
    >
      <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: 12 }}>
        <Flex sx={{ flexDirection: 'row', alignItems: 'flex-start', gap: 16 }}>
          <FontAwesomeIcon
            icon={faBullhorn}
            style={{ fontSize: 48, color: '#CACC4F', marginTop: 4 }}
          />
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              as="span"
              sx={{
                textAlign: 'center',
                fontSize: 48,
                lineHeight: 1,
                fontWeight: 800,
                color: '#333E41',
              }}
              data-testid="suspendedCampaignsCount"
            >
              {count}
            </Box>
            <Box
              as="span"
              sx={{ fontSize: 10, color: '#4D5659', fontWeight: 600 }}
            >
              Suspended Campaigns
            </Box>
          </Flex>
        </Flex>
        <Button
          color="secondary"
          shape="square"
          data-testid="suspendedCampaignsButton"
          size="small"
          onClick={() =>
            history.push({
              pathname: '/suspended-campaigns',
              search: '?status=ACTIVE',
            })
          }
          style={{ borderRadius: 4 }}
        >
          <FontAwesomeIcon icon={faHand} />
          View Suspended Campaigns
        </Button>
      </Flex>
    </Box>
  );
};

export default CampaignSuspendSection;

import { BoxV2 as Box } from 'portal-commons';
import { FunctionComponent } from 'react';

const PageTitle: FunctionComponent = ({ children }) => {
  return (
    <Box
      sx={{
        fontWeight: 800,
        fontSize: '27px',
        lineHeight: '32px',
        color: '#333e41',
      }}
    >
      {children}
    </Box>
  );
};

export default PageTitle;

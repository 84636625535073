import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, TextField } from '@material-ui/core';
import IntegrationReactSelect from '../../../../shared_elements/ui_elements/IntegrationReactSelect';
import { updateCampaignStatusApi, fileComplaintApi } from '../apiServices';
import { getSuspensionCategoryOptions } from '../../Brands/apiServices';
import { toastFlashMessage, isValidInput } from '../../../../utils';
import { Button, Flex, BoxV2 as Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRotateRight,
  faSquareMinus,
  faSquareExclamation,
} from '@fortawesome/pro-regular-svg-icons';

const ModalType = {
  suspend: {
    title: 'Suspend Campaign',
    icon: faSquareMinus,
    cancelBtnId: 'suspendCancelButton',
    submitBtnId: 'suspendSubmitButton',
    submitBtnText: 'Suspend',
  },
  report: {
    title: 'File a Complaint',
    icon: faSquareExclamation,
    cancelBtnId: 'reportCancelButton',
    submitBtnId: 'reportSubmitButton',
    submitBtnText: 'File a Complaint',
  },
  reject: {
    title: 'Reject Campaign',
    icon: faSquareMinus,
    cancelBtnId: 'rejectCancelButton',
    submitBtnId: 'rejectSubmitButton',
    submitBtnText: 'Reject',
  },
  'lift-suspension': {
    title: 'Lift Suspension',
    icon: faRotateRight,
    cancelBtnId: 'liftSuspensionCancelButton',
    submitBtnId: 'liftSuspensionSubmitButton',
    submitBtnText: 'Lift Suspension',
  },
};

export default function CampaignActionModal(props) {
  const [loader, setLoader] = useState(false);
  const { open, type, campaign, handleClose, handleOperationStatus } = props;
  const [error, setError] = useState({ explanation: '' });
  const [actionObj, setActionObj] = useState({
    explanation: '',
  });
  const [categoryOptions, setCategoryOptions] = useState([
    { label: 'Select Reason', value: undefined },
  ]);
  const [suspensionCategory, setSuspensionCategory] = useState();
  const modalType = ModalType[type] ?? ModalType.suspend;

  const handleChange = (value) => {
    setActionObj({
      ...actionObj,
      explanation: value,
    });
    setError({
      ...error,
      explanation: '',
    });
  };

  const handleError = (value) => {
    setError({
      ...error,
      explanation:
        value.trim() === ''
          ? 'Please enter Details'
          : isValidInput(value)
          ? ''
          : 'Invalid input',
    });
  };

  const handleCloseModal = () => {
    setActionObj({ explanation: '' });
    setSuspensionCategory();
    setError({ explanation: '' });
    handleClose();
  };

  function updateCampaignStatus(e) {
    e.preventDefault();
    if (actionObj.explanation && actionObj.explanation.trim()) {
      //report campaign [REVIEW, APPROVED, REJECT, SUSPENDED
      if (isValidInput(actionObj.explanation)) {
        setLoader(true);
        if (type === 'report') {
          fileComplaintApi(campaign.uid, {
            details: actionObj.explanation.trim(),
          }).then((response) => {
            setLoader(false);
            if (response.status >= 200 && response.status < 300) {
              toastFlashMessage(`Campaign reported successfully`, 'success');
              handleCloseModal();
            }
          });
        } else {
          updateCampaignStatusApi(campaign.uid, {
            suspensionCategory,
            explanation: actionObj.explanation.trim(),
            status:
              type === 'suspend'
                ? 'SUSPENDED'
                : type === 'reject'
                ? 'REJECTED'
                : 'REGISTERED',
          }).then((response) => {
            setLoader(false);
            if (response.status >= 200 && response.status < 300) {
              handleOperationStatus(type);
              toastFlashMessage(
                `Campaign ${
                  type === 'suspend'
                    ? 'suspended'
                    : type === 'reject'
                    ? 'rejected'
                    : 'lifted suspension'
                } successfully`,
                'success'
              );
              handleCloseModal();
            }
          });
        }
      } else {
        setError({
          ...error,
          explanation: 'Invalid input',
        });
      }
    } else {
      handleError(actionObj.explanation);
    }
  }

  const isSubmitDisabled = () => {
    return loader || Object.keys(error).find((k) => error[k] !== '')
      ? true
      : false;
  };

  const getAllSuspensionCategories = async () => {
    const options = await getSuspensionCategoryOptions();
    setCategoryOptions((prevState) => {
      return [...prevState, ...options];
    });
  };

  useEffect(() => {
    getAllSuspensionCategories();
  }, []);

  return (
    <Modal
      className="campaign-action-form"
      open={open}
      onClose={(e, reason) => reason !== 'backdropClick' && handleCloseModal()}
      BackdropComponent={Backdrop}
    >
      <Flex
        sx={{
          p: '24px 32px',
          width: 525,
          maxHeight: '100vh',
          flexDirection: 'column',
          boxSizing: 'border-box',
          bg: 't.white',
          overflowY: 'auto',
        }}
      >
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            color: 't.black80',
            py: 12,
            fontSize: 'H600',
            fontWeight: 600,
            lineHeight: 'normal',
          }}
        >
          <FontAwesomeIcon icon={modalType.icon} style={{ marginRight: 8 }} />
          {modalType.title}
        </Flex>
        <Box
          sx={{
            fontSize: 'H100',
            lineHeight: 'H200',
            mb: 8,
            fontWeight: 400,
            color: 't.black80',
          }}
        >
          * Indicates a Required Field
        </Box>
        <div className="form-group-field">
          <TextField
            value={campaign?.uid}
            label="Campaign ID"
            fullWidth
            inputProps={{ 'aria-label': 'campaign Id' }}
            disabled
          />
        </div>
        <div className="form-group-field">
          <TextField
            value={campaign?.cspUid || campaign?.brand?.csp?.uid}
            id="csp"
            label="CSP"
            name="csp"
            fullWidth
            inputProps={{ 'aria-label': 'campaign Id' }}
            disabled
          />
        </div>
        <div className="form-group-field">
          <TextField
            value={campaign?.brand?.displayName || campaign?.brandName || 'N/A'}
            id="brandName"
            label="Brand Name"
            fullWidth
            name="brandName"
            inputProps={{ 'aria-label': 'campaign Id' }}
            disabled
          />
        </div>
        {type === 'suspend' && (
          <div className="form-group-field">
            <IntegrationReactSelect
              options={categoryOptions}
              label="Campaign Suspension Category"
              placeholder="Select Reason"
              value={categoryOptions.find(
                (option) => option.value === suspensionCategory
              )}
              inputProps={{
                'aria-label': 'suspensionCategory',
                'data-testid': 'suspensionCategorySelect',
              }}
              handleChange={(option) => setSuspensionCategory(option.value)}
              keyName="suspensionCategory"
            />
          </div>
        )}
        <div className="form-group-field">
          <TextField
            value={actionObj?.explanation ?? ''}
            multiline
            maxRows={5}
            id="details"
            label="Details"
            required
            autoComplete="off"
            autoFocus
            error={error.explanation ? true : false}
            inputProps={{
              autoFocus: true,
              'aria-label': 'campaign Id',
              maxLength: 3000,
            }}
            fullWidth
            name="details"
            onChange={(e) => handleChange(e.target.value)}
            onBlur={(e) => handleError(e.target.value)}
          />
          {error.explanation && (
            <h6 className="error-msg">{error.explanation}</h6>
          )}
        </div>

        <Flex
          sx={{
            alignContent: 'center',
            justifyContent: 'center',
            m: 24,
            gap: 20,
          }}
        >
          <Button
            data-testid={modalType.cancelBtnId}
            shape="rounded"
            variant="outline"
            color={type === 'lift-suspension' ? 'secondary' : 'primary'}
            disabled={loader}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            data-testid={modalType.submitBtnId}
            shape="rounded"
            color={type === 'lift-suspension' ? 'secondary' : 'primary'}
            disabled={isSubmitDisabled()}
            onClick={updateCampaignStatus}
          >
            {modalType.submitBtnText}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

import {
  ApprovedDca,
  BrandSuggestion,
  CampaignsData,
  CspSuggestion,
  UsecaseType,
} from './types';
import { globalGetService } from '../../../utils/globalApiServices';
import { Pagination } from '../../../utils/types';

export const getBrandSuggestions = async (
  queries: Record<string, any> = {}
) => {
  const response = await globalGetService('mno/brands/suggestions', {
    ...queries,
    limit: 20,
  });
  return response?.ok
    ? response.data.map((suggestion: BrandSuggestion) => suggestion.brandName)
    : [];
};

export const getCspSuggestions = async (queries: Record<string, any> = {}) => {
  const response = await globalGetService('mno/csp/suggestions', {
    ...queries,
    limit: 20,
  });
  return response?.ok
    ? response.data.map((suggestion: CspSuggestion) => suggestion.cspName)
    : [];
};

export const getApprovedDcas = async () => {
  const response = await globalGetService('mno/profile/approvedDca');
  return response?.ok ? (response.data as ApprovedDca[]) : [];
};

export const getUsecaseTypes = async () => {
  const response = await globalGetService('enums/usecaseTypes');
  return response?.ok ? (response.data as UsecaseType[]) : [];
};

export const getCampaigns = async (queries: Record<string, any> = {}) => {
  const response = await globalGetService('mno/campaigns', {
    ...queries,
  });
  return response?.ok
    ? (response.data as Pagination<CampaignsData>)
    : undefined;
};

import {
  BoxV2 as Box,
  FilterConfig,
  FilterType,
  Flex,
  Table,
  TableFilter,
  withFilters,
  WithFiltersConfig,
} from 'portal-commons';
import queryString from 'query-string';
import { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getApprovedDcas,
  getBrandSuggestions,
  getCampaigns,
  getCspSuggestions,
  getUsecaseTypes,
} from '../apis';
import { DateRangePresets, MaxCampaignCount } from '../constants';
import { PageContainer, PageTitle } from '../../../../components';
import { FilterToggleButton, Loader } from '../../../../shared_elements';
import { SelectItem } from '../../../../utils/types';

import '../assets/suspended-campaigns.scss';
import { CampaignsData } from '../types';
import SuspendedCampaignListingRow from '../components/SuspendedCampaignListingRow';
import { convertTimeWithTimezone } from '../../../../utils/time';

interface SuspendedCampaignProps extends WithFiltersConfig {
  filter: {
    configs: Record<string, FilterConfig>;
    candidateFilters: Record<string, string>;
    appliedFilters: Record<string, string>;
    handleEdit: (value: Record<string, string>) => void;
    handleApply: (value: Record<string, string>) => void;
    setOptions: (field: string, options: SelectItem[]) => void;
    updateConfigObject: (newConfigs: Record<string, FilterConfig>) => void;
  };
}

const FilterConfigs = {
  uid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter Campaign ID',
    width: 164,
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter Brand ID',
    width: 164,
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter Brand Name',
    suggestions: [],
    width: 233,
  },
  cspName: {
    type: FilterType.Text,
    label: 'CSP Name',
    placeholder: 'Enter CSP Name',
    suggestions: [],
    width: 233,
  },
  dcaUid: {
    type: FilterType.Dropdown,
    options: [],
    label: 'DCA Name',
    placeholder: 'DCA Name',
    width: 233,
  },
  usecase: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Use Case',
    placeholder: 'Use-Case',
    width: 236,
  },
  suspensionDateRange: {
    type: FilterType.DateRange,
    label: 'Suspended On',
    placeholder: 'Select Date Range',
    dateRangePresets: DateRangePresets,
    // workaround for DateRangePicker bug
    delayUpdate: false,
    width: 250,
  },
  suspensionRole: {
    type: FilterType.Dropdown,
    options: [
      { label: 'DCA', value: 'DCA' },
      { label: 'MNO', value: 'MNO' },
    ],
    label: 'Suspended By',
    placeholder: 'Suspended By',
    width: 187,
  },
  status: {
    type: FilterType.Dropdown,
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Deactivated', value: 'EXPIRED' },
    ],
    label: 'TCR Status',
    placeholder: 'TCR Status',
    width: 212,
  },
};

const DefaultAdditionalFilters = {
  sortField: 'mnoSuspensionDate',
  ascendingOrder: false,
};

const HeaderConfigs = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'cspName', label: 'CSP NAME', sortable: true },
  { id: 'mnoDcaName', label: 'DCA NAME', sortable: false },
  { id: 'usecase', label: 'USE-CASE', sortable: true },
  { id: 'mnoSuspensionDate', label: 'SUSPENDED ON', sortable: true },
  { id: 'mnoSuspensionRole', label: 'SUSPENDED BY', sortable: false },
  { id: 'mnoSuspensionExplanation', label: 'EXPLANATION', sortable: false },
  { id: 'tcrStatus', label: 'TCR STATUS', sortable: false },
];

const SuspendedCampaigns: FunctionComponent<SuspendedCampaignProps> = ({
  filter,
}) => {
  const {
    configs,
    candidateFilters,
    appliedFilters,
    handleEdit,
    handleApply,
    setOptions,
  } = filter;
  const history = useHistory();
  const location = useLocation();
  const [pageLoading, setPageLoading] = useState(true);
  const [filterVisible, setFilterVisible] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [additionalFilters, setAdditionalFilters] = useState(
    DefaultAdditionalFilters
  );
  const [page, setPage] = useState(1);
  const [campaigns, setCampaigns] = useState<CampaignsData[]>([]);
  const [displayedTotalCampaigns, setDisplayedTotalCampaigns] = useState(0);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [campaignsPerPage, setCampaignsPerPage] = useState(10);

  const parseSearchParams = (params: string) => {
    const queries = queryString.parse(params, { decode: true });
    const newAdditionalFilters =
      Object.keys(queries).length === 0
        ? DefaultAdditionalFilters
        : queries.sortField || queries.ascendingOrder
        ? {
            sortField: queries.sortField as string,
            ascendingOrder: JSON.parse(queries.ascendingOrder as string),
          }
        : additionalFilters;
    setAdditionalFilters(newAdditionalFilters);

    const dateRangeFilters: Record<string, any> = {};
    if (appliedFilters.suspensionDateRange) {
      const dates = appliedFilters.suspensionDateRange.split(' - ');
      if (dates.length === 2) {
        dateRangeFilters.startSuspensionDate = convertTimeWithTimezone(
          dates[0],
          undefined,
          'YYYY-MM-DD'
        );
        dateRangeFilters.endSuspensionDate = convertTimeWithTimezone(
          dates[1],
          undefined,
          'YYYY-MM-DD'
        );
      }
    }
    return {
      ...newAdditionalFilters,
      ...appliedFilters,
      ...dateRangeFilters,
      page: queries.page ? queries.page : 1,
    };
  };

  const writeQueryString = (queries: Record<string, any>) => {
    history.push({
      search: `?${queryString.stringify(queries, { encode: true })}`,
    });
  };

  const fetchDcaOptions = async () => {
    const response = await getApprovedDcas();
    setOptions(
      'dcaUid',
      response.map((approvedDca) => ({
        label: approvedDca.displayName,
        value: approvedDca.uid,
      }))
    );
  };

  const fetchUsecaseOptions = async () => {
    const response = await getUsecaseTypes();
    setOptions(
      'usecase',
      response.map((usecaseType) => ({
        label: usecaseType.displayName,
        value: usecaseType.id,
      }))
    );
  };

  const fetchCampaigns = async (queries: Record<string, any>) => {
    setTableLoading(true);
    const response = await getCampaigns({ ...queries, mnoStatus: 'SUSPENDED' });
    setTableLoading(false);
    if (response) {
      setPageLoading(false);
      setPage(response.page);
      setCampaigns(response.records);
      setDisplayedTotalCampaigns(response.totalRecords);
      setTotalCampaigns(Math.min(response.totalRecords, MaxCampaignCount));
      setCampaignsPerPage(response.recordsPerPage);

      if (
        response.records.length === 0 &&
        response.totalRecords > 0 &&
        response.page > 1
      ) {
        const lastPage = Math.ceil(
          response.totalRecords / response.recordsPerPage
        );
        setPageLoading(true);
        writeQueryString({ ...queries, page: lastPage });
      }
    }
  };

  const handleCandidateFiltersChange = (values: Record<string, string>) => {
    handleEdit(values);
  };

  const handleAppliedFiltersChange = (values: Record<string, string>) => {
    handleApply(values);
  };

  const handlePageChange = (page: number) => {
    writeQueryString({
      ...additionalFilters,
      ...appliedFilters,
      page,
    });
  };

  const handleSortFieldChange = (
    sortField: string,
    ascendingOrder: boolean
  ) => {
    const newAdditionalFilters = {
      ...additionalFilters,
      sortField,
      ascendingOrder,
    };
    setAdditionalFilters(newAdditionalFilters);

    if (totalCampaigns > 0) {
      writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page,
        sortField,
        ascendingOrder,
      });
    }
  };

  useEffect(() => {
    fetchDcaOptions();
    fetchUsecaseOptions();
  }, []);

  useEffect(() => {
    const queries = parseSearchParams(location.search);
    fetchCampaigns(queries);
  }, [location.search]);

  return (
    <Box sx={{ width: '100%' }}>
      {pageLoading ? (
        <Loader />
      ) : (
        <PageContainer>
          <Flex sx={{ flexDirection: 'column', rowGap: '4px', width: '100%' }}>
            <PageTitle>{displayedTotalCampaigns} Suspended Campaigns</PageTitle>
            <Box sx={{ marginBottom: filterVisible ? 0 : '4px' }}>
              <FilterToggleButton
                visible={filterVisible}
                onClick={() => setFilterVisible(!filterVisible)}
              />
            </Box>
          </Flex>
          {filterVisible && (
            <TableFilter
              portal="mno"
              configs={configs}
              candidateValues={candidateFilters}
              appliedValues={appliedFilters}
              onCandidateValuesChange={handleCandidateFiltersChange}
              onAppliedValuesChange={handleAppliedFiltersChange}
              className="filters"
              data-testid="suspendedCampaigns"
            />
          )}
          <Table
            testId="campaignsTable"
            loading={tableLoading}
            handleChangePage={handlePageChange}
            createSortHandler={handleSortFieldChange}
            emptyState="no campaigns to view"
            filter={additionalFilters}
            headRows={HeaderConfigs}
            tableData={campaigns.map((campaign) => (
              <SuspendedCampaignListingRow
                key={campaign.uid}
                campaign={campaign}
              />
            ))}
            rowKey="uid"
            pagination={{
              page,
              rowsPerPage: campaignsPerPage,
              totalRecords: totalCampaigns,
            }}
          />
        </PageContainer>
      )}
    </Box>
  );
};

export default withFilters(SuspendedCampaigns, {
  configs: FilterConfigs,
  loaders: {
    suggestion: {
      brandName: {
        minLength: 2,
        load: (value) => getBrandSuggestions({ prefix: value }),
      },
      cspName: {
        minLength: 2,
        load: (value) => getCspSuggestions({ prefix: value }),
      },
    },
  },
});

import { Flex } from 'portal-commons';
import { FunctionComponent } from 'react';

const PageContainer: FunctionComponent = ({ children }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        rowGap: '8px',
        width: '100%',
        padding: '23px 24px 0 18px',
        boxSizing: 'border-box',
      }}
    >
      {children}
    </Flex>
  );
};

export default PageContainer;

import { sub } from 'date-fns';

const Today = new Date();
Today.setHours(0, 0, 0, 0);

export const MaxCampaignCount = 10000;

export const ATTACHMENT_FOLDER = {
  MMS: 'MMS_SAMPLE_MEDIA',
  SUPPORTING_DOC: 'SUPPORTING_DOCUMENT',
};

export const MIGRATION_STATUS_LABEL_MAP = {
  ACTIVE: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'No',
};

export const DateRangePresets = [
  {
    label: 'Today',
    start: Today,
    end: Today,
  },
  {
    label: 'Last 7 days',
    start: sub(Today, { days: 7 }),
    end: Today,
  },
  {
    label: 'Last 1 month',
    start: sub(Today, { months: 1 }),
    end: Today,
  },
  {
    label: 'Last 6 months',
    start: sub(Today, { months: 6 }),
    end: Today,
  },
];

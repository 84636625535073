import { BoxV2 as Box } from 'portal-commons';
import { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';

import { CampaignsData } from '../types';
import { remapTCRStatus } from '../utils';
import {
  convertTimeWithTimezone,
  US_DATE_FORMAT,
} from '../../../../utils/time';
import ExplanationTooltip from './ExplanationTooltip';

interface SuspendedCampaignsListingRow {
  campaign: CampaignsData;
}

const SuspendedCampaignsListingRow: FunctionComponent<
  SuspendedCampaignsListingRow
> = ({ campaign }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <TableRow
      data-testid="suspendedCampaignsListingRow"
      className="link"
      onClick={() =>
        history.push({
          pathname: `/campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${location.pathname}${location.search ?? ''}`,
          },
        })
      }
    >
      <TableCell align="left" data-testid="campaignsListingRowUid">
        <span>{campaign.uid}</span>
      </TableCell>
      <TableCell align="left" data-testid="campaignsListingRowBrandUid">
        {campaign.brandUid}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 130, maxWidth: 200, overflowWrap: 'break-word' }}
        data-testid="campaignsListingRowBrandName"
      >
        {campaign.usecase === 'SOLE_PROPRIETOR'
          ? 'Redacted due to PII'
          : campaign.brandName || 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 130, maxWidth: 200, overflowWrap: 'break-word' }}
        data-testid="campaignsListingRowCspName"
      >
        {campaign.cspName ?? 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 130, maxWidth: 200, overflowWrap: 'break-word' }}
        data-testid="campaignsListingRowDcaName"
      >
        {campaign.mnoDcaName ?? 'No DCA'}
      </TableCell>
      <TableCell align="left" data-testid="campaignsListingRowUsecase">
        {campaign.usecase}
      </TableCell>
      <TableCell
        align="left"
        data-testid="campaignsListingRowMnoSuspensionDate"
      >
        {campaign.mnoSuspensionDate
          ? convertTimeWithTimezone(
              campaign.mnoSuspensionDate,
              undefined,
              US_DATE_FORMAT
            )
          : 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        data-testid="campaignsListingRowMnoSuspensionRole"
      >
        {campaign.mnoSuspensionRole ?? 'N/A'}
      </TableCell>
      <TableCell
        style={{ maxWidth: 123 }}
        align="left"
        data-testid="campaignsListingRowMnoSuspensionExplanation"
      >
        {campaign.mnoSuspensionExplanation ? (
          <ExplanationTooltip
            placement="bottom-end"
            title={campaign.mnoSuspensionExplanation}
          >
            <Box
              sx={{
                width: 'fit-content',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {campaign.mnoSuspensionExplanation}
            </Box>
          </ExplanationTooltip>
        ) : (
          'N/A'
        )}
      </TableCell>
      <TableCell align="left" data-testid="campaignsListingRowStatus">
        {remapTCRStatus(campaign.status)}
      </TableCell>
    </TableRow>
  );
};

export default SuspendedCampaignsListingRow;
